<template>
  <div>
    <div class="row">
      <div class="col-md-12" style="margin-bottom:25px;background-color:#fff;">
        <b-form inline>
          <label class="mr-sm-2" for="inline-form-custom-select-pref"
            >Filters</label
          >
          <b-form-input
            id="input-userId"
            v-model="filters.userId"
            type="text"
            placeholder="Enter User Id"
            style="min-width:300px;"
          ></b-form-input>
          <b-form-input
            id="input-productId"
            v-model="filters.productId"
            type="text"
            placeholder="Enter product Id"
          ></b-form-input>
          <b-form-input
            id="input-platform"
            v-model="filters.platform"
            type="text"
            placeholder="Enter platform"
          ></b-form-input>
          <b-button type="button" variant="primary" v-on:click="applyFilters"
            >Apply Filters</b-button
          >
        </b-form>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :isLoading.sync="isLoading"
          :pagination-options="{
            enabled: true,
            perPage: 20,
            perPageDropdown: [20],
            dropdownAllowAll: false
          }"
          :rows="rows"
          :columns="columns"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <div>
                <b-button
                  ref="btnShow"
                  type="button"
                  variant="outline-success"
                  v-on:click="getSubscription(props.formattedRow.id)"
                  >Show</b-button
                >

                <router-link
                  :to="{
                    name: 'subscriptionForm',
                    params: { id: props.formattedRow.id }
                  }"
                >
                  <b-button variant="outline-primary">Edit</b-button>
                </router-link>
              </div>
            </span>
            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <b-modal
      id="bv-modal"
      title="Subscription Detail"
      scrollable
      hide-footer
      @hidden="onHidden"
    >
      <b-list-group>
        <b-list-group-item
          ><strong>Platform :</strong>
          {{ this.item.platform }}</b-list-group-item
        >
        <b-list-group-item
          ><strong>User Id :</strong> {{ this.item.userId }}</b-list-group-item
        >
        <b-list-group-item
          ><strong>Original Transaction Id :</strong>
          {{ this.item.originalTransactionId }}</b-list-group-item
        >
        <b-list-group-item
          ><strong>Order Id :</strong>
          {{ this.item.orderId }}</b-list-group-item
        >
        <b-list-group-item
          ><strong>Start Date :</strong>
          {{ this.item.startDateMs }}</b-list-group-item
        >
        <b-list-group-item
          ><strong>Expire Date :</strong>
          {{ this.item.expirationDateMs }}</b-list-group-item
        >
        <b-list-group-item
          ><strong>Is Cancelled ? :</strong>
          {{ this.item.isCancelled ? "YES" : "NO" }}</b-list-group-item
        >
        <b-list-group-item
          ><strong>Is Expired ? :</strong>
          {{ this.item.isExpired ? "YES" : "NO" }}</b-list-group-item
        >
        <b-list-group-item
          ><strong>Is Active ? :</strong>
          {{ this.item.isCancelled ? "YES" : "NO" }}</b-list-group-item
        >

        <b-list-group-item
          ><strong>Is GracePeriod ? :</strong>
          {{ this.item.isGracePeriod ? "YES" : "NO" }}
          {{
            this.item.isGracePeriod ? this.item.gracePeriodDateMs : ""
          }}</b-list-group-item
        >

        <b-list-group-item
          ><strong>Created At :</strong>
          {{ this.item.createdAt }}</b-list-group-item
        >
        <b-list-group-item
          ><strong>Updated At :</strong>
          {{ this.item.updatedAt }}</b-list-group-item
        >
      </b-list-group>

      <h3>Subscription Histories</h3>
      <b-spinner small v-if="isHistoryLoading"></b-spinner>
      <b-table striped hover :items="histories" stickyColumn="true"></b-table>
    </b-modal>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import moment from "moment";

export default {
  data() {
    return {
      isLoading: true,
      item: {},
      isHistoryLoading: false,
      histories: [],
      columns: [
        {
          label: "ID",
          field: "id"
        },
        {
          label: "Platform",
          field: "platform",
          sortable: false
        },
        {
          label: "Product",
          field: "productName",
          sortable: false
        },
        {
          label: "Start Date",
          field: "startDateMs"
        },
        {
          label: "Expire Date",
          field: "expirationDateMs"
        },
        {
          label: "Expired",
          field: "isExpired"
        },
        {
          label: "Actions",
          field: "actions",
          sortable: false
        }
      ],
      filters: {
        userId: "",
        productId: "",
        platform: ""
      },
      rows: [],
      totalRecords: 0,
      serverParams: {
        sort: {
          field: "id",
          type: "desc"
        },
        page: 1,
        perPage: 20
      }
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Subscriptions",
        actions: [
          {
            to: "/subscriptionForm",
            name: "New Subscription",
            btnClass: "btn-success"
          }
        ]
      }
    ]);
    this.loadItems();
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    applyFilters() {
      const that = this;
      const filters = this.filters;
      Object.keys(filters).forEach(function(key) {
        if (filters[key] !== "") {
          let newParam = {};
          if(key==='userId'){
            newParam[key] = `*%${filters[key]}%`;
          }else{
            newParam[key] = filters[key];
          }
          that.updateParams(newParam);
        } else {
          delete that.serverParams[key];
        }
      });
      this.loadItems();
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },
    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    // load items is what brings back the rows from server
    loadItems() {
      this.isLoading = true;
      return new Promise(resolve => {
        ApiService.get("subscription", this.serverParams)
          .then(({ data }) => {
            const products = JSON.parse(process.env.PRODUCTS);
            let items = data.response.items;
            items.map(item => {
              item.productName = products[item.productId];
              item.startDateMs = moment(parseInt(item.startDateMs, 10)).format(
                "MM/DD/YYYY LT"
              );
              item.expirationDateMs = moment(
                parseInt(item.expirationDateMs, 10)
              ).format("MM/DD/YYYY LT");
            });

            this.rows = items;
            this.totalRecords = data.response.itemCount;
            this.isLoading = false;
            resolve();
          })
          .catch(e => {
            console.log(e.message);
          });
      });
    },
    getItem: function(id) {
      return new Promise(resolve => {
        ApiService.get("subscription", { id: id })
          .then(({ data }) => {
            let item =
              data.response && data.response.items.length > 0
                ? data.response.items[0]
                : null;
            if (item) {
              item.startDateMs = moment(parseInt(item.startDateMs, 10)).format(
                "MM/DD/YYYY LT"
              );

              item.expirationDateMs = moment(
                parseInt(item.expirationDateMs, 10)
              ).format("MM/DD/YYYY LT");

              item.gracePeriodDateMs = item.gracePeriodDateMs
                ? moment(parseInt(item.gracePeriodDateMs, 10)).format(
                    "MM/DD/YYYY LT"
                  )
                : "";

              item.createdAt = moment(item.createdAt).format("MM/DD/YYYY LT");
              item.updatedAt = moment(item.updatedAt).format("MM/DD/YYYY LT");
              this.item = item;
            } else {
              this.item = {};
            }
            resolve();
          })
          .catch(() => {
            this.$bvToast.toast(
              "Something went wrong while retrieving data from external server...",
              {
                title: "Error",
                variant: "danger"
              }
            );
          });
      });
    },
    getHistories(id) {
      return new Promise(resolve => {
        ApiService.get("subscription/histories", { subscriptionId: id })
          .then(({ data }) => {
            let histories =
              data.response && data.response.items.length > 0
                ? data.response.items
                : null;
            if (histories) {
              histories.map(history => {
                history.productId = this.productIds[history.productId];
                history.startDateMs = moment(
                  parseInt(history.startDateMs, 10)
                ).format("MM/DD/YYYY LT");
                history.expirationDateMs = moment(
                  parseInt(history.expirationDateMs, 10)
                ).format("MM/DD/YYYY LT");
                history.gracePeriodDateMs = history.gracePeriodDateMs
                  ? moment(parseInt(history.gracePeriodDateMs, 10)).format(
                      "MM/DD/YYYY LT"
                    )
                  : "";
                history.createdAt = moment(history.createdAt).format(
                  "MM/DD/YYYY LT"
                );
              });
              this.histories = histories;
            } else {
              this.histories = [];
            }
            this.isHistoryLoading = false;
            resolve();
          })
          .catch(() => {
            this.$bvToast.toast(
              "Something went wrong while retrieving data from external server...",
              {
                title: "Error",
                variant: "danger"
              }
            );
          });
      });
    },
    getSubscription(id) {
      const that = this;
      this.getItem(id).then(() => {
        this.isHistoryLoading = true;
        that.$bvModal.show("bv-modal");
        that.getHistories(id);
      });
    },
    onHidden() {
      this.histories = [];
    }
  },
  computed: {
    productIds() {
      return JSON.parse(process.env.PRODUCTS);
    }
  }
};
</script>

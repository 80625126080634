var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "col-md-12",
            staticStyle: {
              "margin-bottom": "25px",
              "background-color": "#fff",
            },
          },
          [
            _c(
              "b-form",
              { attrs: { inline: "" } },
              [
                _c(
                  "label",
                  {
                    staticClass: "mr-sm-2",
                    attrs: { for: "inline-form-custom-select-pref" },
                  },
                  [_vm._v("Filters")]
                ),
                _c("b-form-input", {
                  staticStyle: { "min-width": "300px" },
                  attrs: {
                    id: "input-userId",
                    type: "text",
                    placeholder: "Enter User Id",
                  },
                  model: {
                    value: _vm.filters.userId,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "userId", $$v)
                    },
                    expression: "filters.userId",
                  },
                }),
                _c("b-form-input", {
                  attrs: {
                    id: "input-productId",
                    type: "text",
                    placeholder: "Enter product Id",
                  },
                  model: {
                    value: _vm.filters.productId,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "productId", $$v)
                    },
                    expression: "filters.productId",
                  },
                }),
                _c("b-form-input", {
                  attrs: {
                    id: "input-platform",
                    type: "text",
                    placeholder: "Enter platform",
                  },
                  model: {
                    value: _vm.filters.platform,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "platform", $$v)
                    },
                    expression: "filters.platform",
                  },
                }),
                _c(
                  "b-button",
                  {
                    attrs: { type: "button", variant: "primary" },
                    on: { click: _vm.applyFilters },
                  },
                  [_vm._v("Apply Filters")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("vue-good-table", {
              attrs: {
                mode: "remote",
                totalRows: _vm.totalRecords,
                isLoading: _vm.isLoading,
                "pagination-options": {
                  enabled: true,
                  perPage: 20,
                  perPageDropdown: [20],
                  dropdownAllowAll: false,
                },
                rows: _vm.rows,
                columns: _vm.columns,
              },
              on: {
                "on-page-change": _vm.onPageChange,
                "on-sort-change": _vm.onSortChange,
                "on-column-filter": _vm.onColumnFilter,
                "on-per-page-change": _vm.onPerPageChange,
                "update:isLoading": function ($event) {
                  _vm.isLoading = $event
                },
                "update:is-loading": function ($event) {
                  _vm.isLoading = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "table-row",
                  fn: function (props) {
                    return [
                      props.column.field == "actions"
                        ? _c("span", [
                            _c(
                              "div",
                              [
                                _c(
                                  "b-button",
                                  {
                                    ref: "btnShow",
                                    attrs: {
                                      type: "button",
                                      variant: "outline-success",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getSubscription(
                                          props.formattedRow.id
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Show")]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "subscriptionForm",
                                        params: { id: props.formattedRow.id },
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      { attrs: { variant: "outline-primary" } },
                                      [_vm._v("Edit")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _c("span", [
                            _vm._v(
                              _vm._s(props.formattedRow[props.column.field])
                            ),
                          ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            id: "bv-modal",
            title: "Subscription Detail",
            scrollable: "",
            "hide-footer": "",
          },
          on: { hidden: _vm.onHidden },
        },
        [
          _c(
            "b-list-group",
            [
              _c("b-list-group-item", [
                _c("strong", [_vm._v("Platform :")]),
                _vm._v(" " + _vm._s(this.item.platform)),
              ]),
              _c("b-list-group-item", [
                _c("strong", [_vm._v("User Id :")]),
                _vm._v(" " + _vm._s(this.item.userId)),
              ]),
              _c("b-list-group-item", [
                _c("strong", [_vm._v("Original Transaction Id :")]),
                _vm._v(" " + _vm._s(this.item.originalTransactionId)),
              ]),
              _c("b-list-group-item", [
                _c("strong", [_vm._v("Order Id :")]),
                _vm._v(" " + _vm._s(this.item.orderId)),
              ]),
              _c("b-list-group-item", [
                _c("strong", [_vm._v("Start Date :")]),
                _vm._v(" " + _vm._s(this.item.startDateMs)),
              ]),
              _c("b-list-group-item", [
                _c("strong", [_vm._v("Expire Date :")]),
                _vm._v(" " + _vm._s(this.item.expirationDateMs)),
              ]),
              _c("b-list-group-item", [
                _c("strong", [_vm._v("Is Cancelled ? :")]),
                _vm._v(" " + _vm._s(this.item.isCancelled ? "YES" : "NO")),
              ]),
              _c("b-list-group-item", [
                _c("strong", [_vm._v("Is Expired ? :")]),
                _vm._v(" " + _vm._s(this.item.isExpired ? "YES" : "NO")),
              ]),
              _c("b-list-group-item", [
                _c("strong", [_vm._v("Is Active ? :")]),
                _vm._v(" " + _vm._s(this.item.isCancelled ? "YES" : "NO")),
              ]),
              _c("b-list-group-item", [
                _c("strong", [_vm._v("Is GracePeriod ? :")]),
                _vm._v(
                  " " +
                    _vm._s(this.item.isGracePeriod ? "YES" : "NO") +
                    " " +
                    _vm._s(
                      this.item.isGracePeriod ? this.item.gracePeriodDateMs : ""
                    )
                ),
              ]),
              _c("b-list-group-item", [
                _c("strong", [_vm._v("Created At :")]),
                _vm._v(" " + _vm._s(this.item.createdAt)),
              ]),
              _c("b-list-group-item", [
                _c("strong", [_vm._v("Updated At :")]),
                _vm._v(" " + _vm._s(this.item.updatedAt)),
              ]),
            ],
            1
          ),
          _c("h3", [_vm._v("Subscription Histories")]),
          _vm.isHistoryLoading
            ? _c("b-spinner", { attrs: { small: "" } })
            : _vm._e(),
          _c("b-table", {
            attrs: {
              striped: "",
              hover: "",
              items: _vm.histories,
              stickyColumn: "true",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }